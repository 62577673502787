import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

import ContactUs from '../../../components/Layout/ContactUs'
import InternationalOffices from '../../../components/ContactUsPage/InternationalOffices'
import SEO from '../../../components/SEO/SEO'

const InternationalOfficesPage = (): ReactElement => {
  const contactUsHeroBanner = useStaticQuery(graphql`
    query {
      heroBanner(title: { eq: "Contact Us" }) {
        image
      }
    }
  `)
  return (
    <ContactUs heroImage={contactUsHeroBanner.heroBanner ? contactUsHeroBanner.heroBanner.image : ''}>
      <SEO title="INTERNATIONAL OFFICES - ALISI | Ayala Land" />
      <InternationalOffices />
    </ContactUs>
  )
}

export default InternationalOfficesPage
