import React from 'react'

import ContentNav from '../../ContentNav'
import Content from '../../Content'
import { graphql, useStaticQuery } from 'gatsby'

import { links } from '../links'

const InternationalOffices = () => {
  const contactData = useStaticQuery(graphql`
    query internationalOffices {
      internationalOffices {
        list {
          title
          address
          telHotline
          telNumber
          email
        }
      }
    }
  `)

  const localOffices: any = contactData && contactData.internationalOffices.list ? contactData.internationalOffices.list : []

  return (
    <>
      <ContentNav links={links} active={1} />
      <Content>
        <h1>International Offices</h1>

        {localOffices.map((office: any) => {
          return (
            <>
              <h2>{office.title}</h2>

              <span>{office.address}</span>
              <br />
              <br />
              <b>
                {office.telHotline && office.telHotline.split(' ').join('-')}
                {office.telNumber && `,${office.telNumber.split(' ').join('-')}`}
              </b>
              {office.email !== '' && office.email && (
                <>
                  <br />
                  <a href={`mailto: ${office.email}`}>
                    <i className="la la-envelope" /> {office.email}
                  </a>
                </>
              )}

              <div className="line-divider" />
            </>
          )
        })}
      </Content>
    </>
  )
}

export default InternationalOffices
